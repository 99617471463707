import { useSessionStore } from "@/stores/session";
/**
 * updates the PCW engine of the watch progress
 * @param {String} externalId - id of the currently played media
 * @param {Number} duration - duration of the player when this function is called
 */
async function sendWatchHistory(externalId, duration) {
  if (!useSessionStore().isAuthenticated) return;
  return await usePostMethod("/api/biz/video/history/update", {
    body: {
      contentId: externalId,
      viewDuration: Math.floor(duration),
      viewSource: "vod",
    },
  });
}

/**
 * fetches the watch history of the list of contentIds
 * @param {String} viewType one of [next-to-watch|viewing-history|next-episode]
 * @param {*} contentIds array of contentIds for request
 * @returns
 */
async function fetchWatchHistory(viewType, contentId) {
  if (!useSessionStore().isAuthenticated) return;
  const res = await useGetMethod("/api/biz/video/history", {
    viewType,
    contentId,
  });

  return res;
}

async function fetchEpisodeHistory(contentId) {
  if (!useSessionStore().isAuthenticated) return;
  const res = await useGetMethod("/api/biz/video/history/season/episodes", {
    viewType: "viewing-history",
    contentId,
  });

  return res;
}

export default function () {
  return {
    sendWatchHistory,
    fetchWatchHistory,
    fetchEpisodeHistory,
  };
}
